import StoreIds from './storeIds';
import {
  useSecureSessionPost,
  useSessionGet,
} from '~/composables/dataFetching/genericFetchers';
import type { ShopCart } from '~/server/gateway/connections/shopware/loaders/loadCart';
import type { Result as LoadResult } from '~/server/api/[site]/shop/cart/load.get';
import type { Result as AddResult } from '~/server/api/[site]/shop/cart/add.post';
import type { SiteIdent } from '~/@types/siteIdent';
import { UseStateKeys } from '@/useStateKeys';
import { v4 as uuidv4 } from 'uuid';
import type { postBody as AddCampaignBody } from '~/server/api/[site]/shop/cart/addCampaign.post';
import type { postBody as UpdateCampaignBody } from '~/server/api/[site]/shop/cart/updateCampaign.post';
import { LineItemType } from '~/@types/lineItemType';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import {
  BasketActionSource,
  BasketQuantityValidationState,
} from '~/@types/basket';
import useMailchimpMarketing from '@/composables/checkout/useMailchimpMarketing';
import { handleLoadingError } from '~/utils/handleLoadingError';
import useGetDebugData from '@/components/debug/useGetDebugData';

export type StoreState = {
  cart: Omit<ShopCart, 'desiredDelivery'> & {
    desiredDelivery: {
      desiredDeliveryDate?: Date;
      firstValidDate?: Date;
      lastValidDate?: Date;
      invalidDates?: Date[];
    };
  };
  hints: {
    drugRemoval: boolean;
    productIdsWithChange?: Set<string>;
    quantityChange?: Record<string, BasketQuantityValidationState>;
  };
  _eventQueue: CartEventUnion[];
  eventQueueRunning: boolean;
  eventQueueCurrentItems: {
    itemId?: string;
    productId?: string;
    campaignId?: string;
  }[];
  eventQueuePreventAddItems: string[];
  _eventQueueRunningPromise: Promise<any> | null;
  initialized: boolean;
  _meta: {
    debug: boolean;
    queueTimer: number | null;
  };
};

export enum CartActions {
  loadCart,
  addProducts,
  updateProducts,
  removeProducts,
  addPromotion,
  removePromotion,
  setDesiredDeliveryDate,
  addCampaign,
  addProductSubscriptions,
  updateProductSubscriptions,
  updateCampaignItem,
  updateCampaignItemChildren,
  replaceItemWithCampaign,
}

export interface CartEvent {
  action: CartActions;
  productId?: string;
  promotionId?: string;
  promotion?: string;
  cartItemId?: string;
  cartItemIds?: string[];
  products?: { productId?: string; quantity?: number; cartItemId?: string }[];
  desiredDeliveryDate?: Date;
  source?: BasketActionSource;
}

//@TODO: Add Pick to specific events

export interface AddProductsEvent extends CartEvent {
  action: CartActions.addProducts;
  products: { productId: string; quantity: number }[];
  source?: BasketActionSource;
}

export interface RemoveProductsEvent extends CartEvent {
  action: CartActions.removeProducts;
  cartItemIds: string[];
}

export interface UpdateProductsEvent extends CartEvent {
  action: CartActions.updateProducts;
  products: { productId: string; quantity: number; cartItemId: string }[];
  source?: BasketActionSource;
}

export interface AddPromotionEvent extends CartEvent {
  action: CartActions.addPromotion;
  promotionId: string;
  quantity: number;
  cartItemId?: string;
}
export interface RemovePromotionEvent extends CartEvent {
  action: CartActions.removePromotion;
  cartItemIds: string[];
}

export interface LoadCartEvent extends CartEvent {
  action: CartActions.loadCart;
}

export interface SetDesiredDeliveryDateEvent extends CartEvent {
  action: CartActions.setDesiredDeliveryDate;
}

export interface AddProductSubscriptionsEvent extends CartEvent {
  action: CartActions.addProductSubscriptions;
  subscriptions: [
    {
      productId: string;
      quantity: number;
      deliveryDay: number;
      interval: string;
    },
  ];
}

export interface UpdateProductSubscriptionsEvent extends CartEvent {
  action: CartActions.updateProductSubscriptions;
  subscriptions: [
    {
      productId: string;
      cartItemId: string;
      quantity: number;
      deliveryDay: number;
      interval: string;
    },
  ];
}

export interface AddCampaignEvent extends CartEvent, AddCampaignBody {
  action: CartActions.addCampaign;
}

export interface UpdateCampaignItemEvent extends CartEvent {
  action: CartActions.updateCampaignItem;
  itemId: string;
  campaignId: string;
  quantity: number;
}
export interface UpdateCampaignItemChildrenEvent
  extends CartEvent,
    UpdateCampaignBody {
  action: CartActions.updateCampaignItemChildren;
}

export interface ReplaceItemWithCamapignEvent extends CartEvent {
  action: CartActions.replaceItemWithCampaign;
  campaign: AddCampaignBody;
  itemId: string;
}

export type CartEventUnion =
  | AddProductsEvent
  | RemoveProductsEvent
  | UpdateProductsEvent
  | AddPromotionEvent
  | RemovePromotionEvent
  | LoadCartEvent
  | SetDesiredDeliveryDateEvent
  | AddCampaignEvent
  | UpdateCampaignItemEvent
  | UpdateCampaignItemChildrenEvent
  | AddProductSubscriptionsEvent
  | ReplaceItemWithCamapignEvent
  | UpdateProductSubscriptionsEvent;

const pagesWithItemDiffAfterLogin = [
  '/checkout/login',
  '/checkout/basket',
  '/checkout/review',
];

/**
 * @prop actions.add/remove/update - All actions are sensitive to what's already in the cart.
 * This means that if you add a product that already exists in the cart, the quantity will be updated instead for example.
 */
export const useCartStore = defineStore(StoreIds.CART, {
  state: () =>
    ({
      cart: {
        items: [],
        price: {
          netPrice: 0,
          totalPrice: 0,
          positionPrice: 0,
          calculatedTaxes: [],
          freeShippingGap: 0,
          shippingCosts: 0,
        },
        voucher: null,
        errors: null,
        desiredDelivery: {},
        token: '',
      },
      hints: {
        productIdsWithChange: null,
        drugRemoval: false,
        quantityChange: null,
      },
      eventQueueRunning: false,
      eventQueueCurrentItems: [],
      eventQueuePreventAddItems: [],
      _eventQueueRunningPromise: null,
      initialized: false,
      _eventQueue: [],
      _meta: {
        debug: null,
        queueTimer: null,
      },
    }) as StoreState,
  actions: {
    /**
     * Loads/Refreshes the cart-data
     */
    async loadCart() {
      if (this._meta.debug === null) {
        this._meta.debug = useGetDebugData()?.value?.store.debug ?? false;
      }
      if (this.cartIsLoading) return;
      this._d('Cart Load', 'INFO');
      this._eventQueue.push({ action: CartActions.loadCart } as LoadCartEvent);
      if (!this.eventQueueRunning) {
        await this._workEventQueue();
        setMailchimpCampaignId(this.cart);
        this.initialized = true;
      }
    },
    /**
     * @deprecated This method is a stub/override as resetting this store is not working out of the box due to the event-queue promise
     */
    $reset() {
      // Doesnt do anything. Implement your own solution that keeps the promise / queue alive on reset.
      // For now we do not really need to reset the Store (just trigger a loadCart for that purpose basically)
    },
    async loadCartAfterLogin() {
      this._d('After Login Cart Load', 'INFO');
      const oldCart = JSON.parse(JSON.stringify(this.cart));
      await this.loadCart();
      await this.eventQueueRunningPromise();

      if (pagesWithItemDiffAfterLogin.includes(useRoute().path.toLowerCase())) {
        this.hints.productIdsWithChange = compareCartsForItemChanges(
          oldCart,
          this.cart,
        );
        this.hints.drugRemoval = compareCartsForDrugRemoval(oldCart, this.cart);
      }
      setMailchimpCampaignId(this.cart, oldCart);
    },
    async removeHints() {
      this.hints.productIdsWithChange = null;
      this.hints.drugRemoval = false;
      this.hints.quantityChange = null;
    },

    async addProduct(
      productId: string,
      quantity: number,
      source = BasketActionSource.CART,
    ) {
      this.addProducts([{ productId, quantity }], source);
    },
    async addProducts(
      products: { productId: string; quantity: number }[],
      source = BasketActionSource.CART,
    ) {
      const productsToUpdate: {
        cartItemId: string;
        productId: string;
        quantity: number;
      }[] = [];

      for (const product of products) {
        const existingCartItem = findExistingItemWithExceptions(
          this.cart,
          product.productId,
        );
        if (existingCartItem) {
          productsToUpdate.push({
            cartItemId: existingCartItem.itemId,
            productId: product.productId,
            quantity:
              /*
              In product finder we need to add the purchaseSteps to the quantity
              because the quantity is always the value of minPurchase which will cause issues
              if the product is already in the cart and has a minPurchase > 1 and purchaseSteps = 1
              */
              source === BasketActionSource.FINDER
                ? existingCartItem.product.purchaseSteps +
                  existingCartItem.quantity
                : product.quantity + existingCartItem.quantity,
          });
        }
      }
      const productsToAdd = products.filter((product) => {
        return !productsToUpdate.some((x) => {
          return x.productId === product.productId;
        });
      });

      if (productsToAdd.length > 0) {
        this._eventQueue.push({
          action: CartActions.addProducts,
          products: productsToAdd,
          source: source,
        } as AddProductsEvent);
      }

      if (productsToUpdate.length > 0) {
        this.updateProducts(productsToUpdate, source);
      }

      this._workEventQueue();
    },

    async updateProduct(
      productId: string,
      cartItemId: string,
      quantity: number,
    ) {
      this.updateProducts([{ productId, cartItemId, quantity }]);
    },
    async updateProducts(
      products: { productId: string; cartItemId: string; quantity: number }[],
      source = BasketActionSource.CART,
    ) {
      const productsToRemove = products.filter(
        (product) => product.quantity < 1,
      );
      if (productsToRemove.length > 0) {
        this.removeProducts(productsToRemove.map((x) => x.cartItemId));
      }

      const productsToUpdate = products.filter(
        (product) => product.quantity > 0,
      );
      if (productsToUpdate.length > 0) {
        this._eventQueue.push({
          action: CartActions.updateProducts,
          products: productsToUpdate,
          source: source,
        } as UpdateProductsEvent);
      }
      this._workEventQueue();
    },
    /**
     *
     * Adds a Product Subscriptionto the cart
     *
     */
    async addProductSubscription(
      productId: string,
      quantity: number,
      deliveryDay: number,
      interval: string,
    ) {
      this.addProductSubscriptions([
        {
          productId,
          quantity,
          deliveryDay,
          interval,
        },
      ]);
    },
    async addProductSubscriptions(
      subscriptions: {
        productId: string;
        quantity: number;
        deliveryDay: number;
        interval: string;
      }[],
    ) {
      const subscriptionsToUpdate: {
        cartItemId: string;
        productId: string;
        quantity: number;
        interval: string;
      }[] = [];

      for (const subscription of subscriptions) {
        const existingCartItem = findExistingSubscription(
          this.cart.items,
          subscription.productId,
        );
        if (existingCartItem) {
          subscriptionsToUpdate.push({
            cartItemId: existingCartItem.itemId,
            productId: subscription.productId,
            quantity: subscription.quantity,
            interval: subscription.interval,
          });
        }
      }

      const subscriptionsToAdd = subscriptions.filter((subscription) => {
        return !subscriptionsToUpdate.some((x) => {
          return x.productId === subscription.productId;
        });
      });

      if (subscriptionsToAdd.length > 0) {
        this._eventQueue.push({
          action: CartActions.addProductSubscriptions,
          subscriptions: subscriptionsToAdd,
        } as AddProductSubscriptionsEvent);
      }

      if (subscriptionsToUpdate.length > 0) {
        this._eventQueue.push({
          action: CartActions.updateProductSubscriptions,
          subscriptions: subscriptionsToUpdate,
        } as UpdateProductSubscriptionsEvent);
      }

      this._workEventQueue();
    },

    async updateProductSubscription(
      productId: string,
      cartItemId: string,
      quantity: number,
      interval: string,
    ) {
      this.updateProductSubscriptions([
        { productId, cartItemId, quantity, interval },
      ]);
    },

    async updateProductSubscriptions(
      subscriptions: {
        productId: string;
        cartItemId: string;
        quantity: number;
        interval: string;
      }[],
    ) {
      const subscriptionsToUpdate = subscriptions.filter(
        (subscription) => subscription.quantity > 0,
      );
      if (subscriptionsToUpdate.length > 0) {
        this._eventQueue.push({
          action: CartActions.updateProductSubscriptions,
          subscriptions: subscriptionsToUpdate,
        } as UpdateProductSubscriptionsEvent);
      }
      this._workEventQueue();
    },

    async removeProduct(cartItemId: string) {
      this.removeProducts([cartItemId]);
    },
    async removeProducts(cartItemIds: string[]) {
      this._eventQueue.push({
        action: CartActions.removeProducts,
        cartItemIds: cartItemIds,
      } as RemoveProductsEvent);
      this._workEventQueue();
    },

    async addPromotion(promotionId: string, quantity: number) {
      this._eventQueue.push({
        action: CartActions.addPromotion,
        promotionId,
        quantity,
      } as AddPromotionEvent);
      this._workEventQueue();
    },
    async removePromotion(cartItemId: string) {
      this._eventQueue.push({
        action: CartActions.removePromotion,
        cartItemIds: [cartItemId],
      } as RemovePromotionEvent);
      this._workEventQueue();
    },

    async setDesiredDeliverDate(desiredDeliveryDate: Date) {
      this._eventQueue.push({
        action: CartActions.setDesiredDeliveryDate,
        desiredDeliveryDate,
      });
      this._workEventQueue();
    },

    async addCampaign({ campaignId, items, quantity }: AddCampaignBody) {
      const existingItem = findExistingCampaignItem(
        this.cart.items,
        campaignId,
        items,
      );
      if (existingItem) {
        this._eventQueue.push({
          action: CartActions.updateCampaignItem,
          itemId: existingItem.itemId,
          campaignId,
          quantity: quantity + existingItem.quantity,
        } as UpdateCampaignItemEvent);
      } else {
        this._eventQueue.push({
          action: CartActions.addCampaign,
          campaignId,
          items,
          quantity,
        } as AddCampaignEvent);
      }

      if (!this.eventQueueRunning) this._workEventQueue();
    },
    async updateCampaignItem({
      campaignId,
      quantity,
      itemId,
    }: Omit<UpdateCampaignItemEvent, 'action'>) {
      this._eventQueue.push({
        action: CartActions.updateCampaignItem,
        campaignId,
        quantity,
        itemId,
      } as UpdateCampaignItemEvent);
      this._workEventQueue();
    },
    async updateCampaignItemChildren({
      campaignLineItemId,
      quantity,
      items,
    }: Omit<UpdateCampaignItemChildrenEvent, 'action'>) {
      this._eventQueue.push({
        action: CartActions.updateCampaignItemChildren,
        campaignLineItemId,
        quantity,
        items,
      } as UpdateCampaignItemChildrenEvent);
      this._workEventQueue();
    },

    async replaceItemWithCampaign({
      campaign,
      itemId,
    }: Omit<ReplaceItemWithCamapignEvent, 'action'>) {
      this._eventQueue.push({
        action: CartActions.replaceItemWithCampaign,
        campaign,
        itemId,
      } as ReplaceItemWithCamapignEvent);
      this._workEventQueue();
    },

    async removeAllDrugItems() {
      const drugItems = this.cart.items.filter(
        (item) => item.product?.flags?.isDrug,
      );
      const cartItemIds = drugItems.map((item) => item.itemId);
      this.removeProducts(cartItemIds);
    },

    /**
     * use this to await the eventQueue to be empty
     */
    async eventQueueRunningPromise() {
      return (
        this._eventQueueRunningPromise ??
        new Promise((resolve) => {
          resolve(true);
        })
      );
    },

    /**
     * You should not need to call this method directly
     */
    _setCart(cart: ShopCart) {
      try {
        if (cart) {
          this.cart.items = cart.items;
          this.cart.price = cart.price;
          this.cart.voucher = cart.voucher;
          this.cart.errors = cart.errors;
          this.cart.desiredDelivery = {
            desiredDeliveryDate: cart.desiredDelivery?.desiredDeliveryDate
              ? new Date(cart.desiredDelivery?.desiredDeliveryDate)
              : null,
            firstValidDate: cart.desiredDelivery?.firstValidDate
              ? new Date(cart.desiredDelivery.firstValidDate)
              : null,
            lastValidDate: cart.desiredDelivery?.lastValidDate
              ? new Date(cart.desiredDelivery.lastValidDate)
              : null,
            invalidDates: cart.desiredDelivery?.invalidDates
              ? cart.desiredDelivery?.invalidDates?.map(
                  (date) => new Date(date),
                )
              : [],
          };

          this.cart.token = cart.token;
        } else {
          throw new Error('Cart does not exist');
        }
      } catch (e: any) {
        useElasticApm()?.captureError({
          name: 'Cart Store',
          message: `Error Setting Cart: ${e.message}`,
          cause: JSON.stringify(e.cause),
          stack: e.stack,
        });
      }
    },
    /**
     * You should not need to call this method directly
     */
    async _workEventQueue(isRunningOverride = false) {
      // Dont'start a new queue if one is already running
      if (this.eventQueueRunning && !isRunningOverride) return;

      // Add a resolver & set promise in Store to resolve once the queue is empty
      let resolver: (v: unknown) => void = null;
      if (!this.eventQueueRunning && !this._eventQueueRunningPromise) {
        this._d(
          `Event-Queue Queue now working, setting _eventQueueRunningPromise...`,
          'INFO',
        );
        this.eventQueueRunning = true;
        this._meta.queueTimer = new Date().getTime();
        this._eventQueueRunningPromise = new Promise((resolve) => {
          resolver = resolve;
        });
      }
      this.eventQueueRunning = true;

      // Actual event handling
      const event = this._eventQueue[0];
      await this._beforeHandleEvent(event);
      const site = useSiteIdent();
      this._d(
        `Event-Queue handling event with action: ${event.action}`,
        'INFO',
      );
      const eventResult = await handleEvent(event, site, this.cart.token);
      await this._afterHandleEvent(event);

      //Show notification if needed
      if (eventResult) {
        this._eventQueue.length === 1 && this._setCart(eventResult);
        if (
          [
            CartActions.addProducts,
            CartActions.updateProducts,
            CartActions.addProductSubscriptions,
            CartActions.updateProductSubscriptions,
            CartActions.addCampaign,
            CartActions.updateCampaignItem,
          ].includes(event.action) &&
          useRoute().path !== '/checkout/basket'
        ) {
          useState(UseStateKeys.HOTLINK_BASKET_NOTIFICATION).value = true;
        }
      }

      //Recursively work through the queue until empty
      this._eventQueue.shift();
      this._d(`Event-Queue Event handling done: ${event.action}`);
      if (this._eventQueue.length) {
        await this._workEventQueue(true);
      }

      /**
       *  The resolver only ever exists on the root-call of this function - when the queue get triggert basically
       *  This part of the code should only ever get called once the event-queue is empty again (so all recursive calls above are done)
       */
      if (resolver) {
        this._d('Queue is finished, resolving _eventQueueRunningPromise...');
        resolver(true);
        this.eventQueueCurrentItems = [];
        this.eventQueuePreventAddItems = [];
        this.eventQueueRunning = false;
        this._eventQueueRunningPromise = null;
        this._d(
          `Event-Queue ran for ${
            new Date().getTime() - this._meta.queueTimer
          }ms`,
          'INFO',
        );
        this._meta.queueTimer = null;
      }
    },
    /**
     * This is not a hook but an internal function!
     */
    async _beforeHandleEvent(event: CartEventUnion) {
      // Deduplicate addProducts
      // This could potentially create update-events from the deduped products to properly reflect user interactions, but let's keep it simple for now
      if (event.action === CartActions.addProducts) {
        event.products = event.products.filter(
          (x) => !this.eventQueuePreventAddItems.includes(x.productId),
        );
      }

      // Set data about current items in queue
      if (
        event.action === CartActions.addProducts ||
        event.action === CartActions.updateProducts
      ) {
        this.eventQueueCurrentItems = event.products.map((product) => {
          return {
            productId: product.productId,
          };
        });
      }
      if (
        event.action === CartActions.addCampaign ||
        event.action === CartActions.updateCampaignItem
      ) {
        this.eventQueueCurrentItems = [{ campaignId: event.campaignId }];
      }
    },
    /**
     * This is not a hook but an internal function!
     */
    async _afterHandleEvent(event: CartEventUnion) {
      if (event.action === CartActions.addProducts) {
        for (const product of (event as AddProductsEvent).products) {
          this.eventQueuePreventAddItems.push(product.productId);
        }
      }
    },
    _d(text: string, lvl: 'INFO' | 'WARN' | 'ERR' | 'NONE' = 'NONE') {
      const icon = {
        INFO: '[i]\t',
        WARN: '⚠️\t',
        ERR: '❌\t',
        NONE: '\t',
      };
      if (this._meta.debug)
        // eslint-disable-next-line no-console
        console.debug(`🛒\tCart-Store: \n${icon[lvl]}${text}`);
    },
    _setDebug(t: boolean) {
      this._meta.debug = t;
    },
  },
  getters: {
    isLoading: (state) => {
      return state.eventQueueRunning;
    },
    cartIsLoading: (state) => {
      return state._eventQueue.find(
        (event) => event.action === CartActions.loadCart,
      );
    },
    itemAmount: (state) => {
      let amount = 0;
      for (const item of state.cart.items) {
        if (item.children) {
          let childrenAmount = 0;
          for (const child of item.children) childrenAmount += child.quantity;
          amount += childrenAmount;
        } else amount += item.quantity;
      }

      return amount;
    },
    voucherAmount: (state) => {
      const amount = state.cart.voucher?.quantity ?? 0;
      return amount;
    },
    hasDrugItem: (state) => {
      return state.cart.items.some((item) => {
        return item.product?.flags?.isDrug;
      });
    },
    drugItems: (state) => {
      return state.cart.items.filter((item) => item.product?.flags?.isDrug);
    },
    cartItemAmount(): number {
      return this.itemAmount;
    },
  },
});

const EventMap: Record<
  CartActions,
  (
    event: CartEvent,
    site: SiteIdent,
    cartToken?: string,
  ) => Promise<ShopCart | null>
> = {
  [CartActions.loadCart]: async (event, site) => {
    const products: {
      productId?: string;
      quantity?: number;
      cartItemId?: string;
    }[] = [];
    if (useCartStore().cart.items.length > 0) {
      products.push(
        ...useCartStore().cart.items.map((product) => {
          return {
            id: product.itemId,
            referencedId: product.productId,
            type: 'product',
            quantity: product.quantity,
          };
        }),
      );
    }
    try {
      const updatedCart = await useSessionGet<LoadResult>(
        `/api/${site}/shop/cart/load`,
      );

      if (products.length > 0 && updatedCart?.items?.length) {
        checkForQuantityChange(
          products,
          updatedCart.items,
          event.source,
          'update',
        );
      }

      return updatedCart;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.addProducts]: async (event, site, cartToken) => {
    const arrayToAdd = (event as AddProductsEvent).products.map((product) => {
      return {
        id: uuidv4(),
        referencedId: product.productId,
        type: 'product',
        quantity: product.quantity,
      };
    });
    try {
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/add`,
        {
          items: arrayToAdd,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        checkForQuantityChange(
          arrayToAdd,
          updatedCart.items,
          event.source,
          'add',
        );
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.updateProducts]: async (event, site, cartToken) => {
    const arrayToUpdate = (event as UpdateProductsEvent).products.map(
      (product) => {
        return {
          id: product.cartItemId,
          referencedId: product.productId,
          type: 'product',
          quantity: product.quantity,
        };
      },
    );
    try {
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/update`,
        {
          items: arrayToUpdate,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        checkForQuantityChange(
          arrayToUpdate,
          updatedCart.items,
          event.source,
          'update',
        );
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.removeProducts]: async (event, site, cartToken) => {
    if (
      useCartStore().hints.quantityChange &&
      Object.keys(useCartStore().hints.quantityChange).length > 0
    ) {
      // Remove hints from quantityChange if item is getting removed from cart
      event.cartItemIds.forEach((cartItemId) => {
        if (cartItemId in useCartStore().hints.quantityChange) {
          delete useCartStore().hints.quantityChange[cartItemId];
        }
      });
    }
    try {
      return await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/remove`,
        {
          ids: event.cartItemIds,
        },
        { headers: { 'cart-token': cartToken } },
      );
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.addPromotion]: async (event, site, cartToken) => {
    const addPromotionEvent = event as AddPromotionEvent;
    try {
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/add`,
        {
          items: [
            {
              id: uuidv4(),
              referencedId: addPromotionEvent.promotionId,
              type: 'promotion',
              quantity: addPromotionEvent.quantity,
            },
          ],
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.removePromotion]: async (event, site, cartToken) => {
    try {
      return await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/remove`,
        {
          ids: event.cartItemIds,
        },
        { headers: { 'cart-token': cartToken } },
      );
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.setDesiredDeliveryDate]: async (event, site, cartToken) => {
    try {
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/desiredDeliveryDate`,
        {
          date: event.desiredDeliveryDate,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.addProductSubscriptions]: async (event, site, cartToken) => {
    const addProductSubscriptionsEvent = event as AddProductSubscriptionsEvent;
    const subscriptionsToAdd = addProductSubscriptionsEvent.subscriptions.map(
      (subscription) => {
        return {
          id: uuidv4(),
          referencedId: subscription.productId,
          type: 'subscription',
          quantity: subscription.quantity,
          desiredDeliveryDay: subscription.deliveryDay,
          interval: subscription.interval,
        };
      },
    );
    try {
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/addSubscription`,
        {
          items: subscriptionsToAdd,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.updateProductSubscriptions]: async (event, site, cartToken) => {
    const updateProductSubscriptionsEvent =
      event as UpdateProductSubscriptionsEvent;
    const subscriptionsToUpdate =
      updateProductSubscriptionsEvent.subscriptions.map((subscription) => {
        return {
          id: subscription.cartItemId,
          referencedId: subscription.productId,
          type: 'subscription',
          quantity: subscription.quantity,
          payload: {
            productSubscriptionInterval: {
              extensions: [] as any[],
              key: subscription.interval,
              name: 'b2bProductSubscription.interval.' + subscription.interval,
              snippet:
                'b2bProductSubscription.interval.' + subscription.interval,
            },
          },
        };
      });
    try {
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/update`,
        {
          items: subscriptionsToUpdate,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.addCampaign]: async (event, site, cartToken) => {
    try {
      const addCampaignEvent = event as AddCampaignEvent;
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/addCampaign`,
        {
          campaignId: addCampaignEvent.campaignId,
          items: addCampaignEvent.items,
          quantity: addCampaignEvent.quantity,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.updateCampaignItemChildren]: async (event, site, cartToken) => {
    try {
      const updateChildrenEvent = event as UpdateCampaignItemChildrenEvent;
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/updateCampaign`,
        {
          campaignLineItemId: updateChildrenEvent.campaignLineItemId,
          items: updateChildrenEvent.items,
          quantity: updateChildrenEvent.quantity,
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.updateCampaignItem]: async (event, site, cartToken) => {
    try {
      const updateEvent = event as UpdateCampaignItemEvent;
      const updatedCart = await useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/update`,
        {
          items: [
            {
              id: updateEvent.itemId,
              referencedId: updateEvent.campaignId,
              quantity: updateEvent.quantity,
            },
          ],
        },
        { headers: { 'cart-token': cartToken } },
      );
      if (updatedCart && typeof updatedCart === 'object') {
        return updatedCart;
      }
      return null;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
  [CartActions.replaceItemWithCampaign]: function (
    event: CartEvent,
    site: SiteIdent,
    cartToken?: string,
  ): Promise<ShopCart> {
    try {
      const replaceEvent = event as ReplaceItemWithCamapignEvent;
      return useSecureSessionPost<AddResult>(
        `/api/${site}/shop/cart/replaceWithCampaign`,
        {
          campaign: replaceEvent.campaign,
          itemId: replaceEvent.itemId,
        },
        { headers: { 'cart-token': cartToken } },
      );
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  },
};

async function handleEvent(
  event: CartEventUnion,
  site: SiteIdent,
  cartToken?: string,
): Promise<ShopCart | null> {
  const handler = EventMap[event.action];
  if (!handler) {
    return null;
  }
  return await handler(event, site, cartToken);
}

function compareCartsForItemChanges(
  cart_Old: Pick<ShopCart, 'items'>,
  cart_New: Pick<ShopCart, 'items'>,
) {
  const itemsToHighlight = new Set<string>();

  for (const item of cart_New.items) {
    const oldItem = cart_Old.items.find(
      (oldItem) => oldItem.itemId === item.itemId,
    );
    if (!oldItem) {
      itemsToHighlight.add(item.itemId);
    } else if (oldItem && oldItem.quantity !== item.quantity) {
      itemsToHighlight.add(item.itemId);
    }
  }

  return itemsToHighlight.size > 0 ? itemsToHighlight : null;
}

function checkForQuantityChange(
  products: any[],
  updatedCartItems: LineItem[],
  source = BasketActionSource.CART,
  action: 'add' | 'update',
) {
  let quantityChangeHints = useCartStore().hints.quantityChange;

  products.forEach((product) => {
    const updatedCartItem = updatedCartItems.find(
      (item) => item.itemId === product.id,
    );
    if (!updatedCartItem) {
      return;
    }

    // Initialize quantityChangeHints if null
    if (!quantityChangeHints) {
      quantityChangeHints = {};
    }

    if (
      source === BasketActionSource.FINDER &&
      action === 'add' &&
      updatedCartItem.quantityInformation?.minPurchase > 1
    ) {
      quantityChangeHints[updatedCartItem.itemId] =
        BasketQuantityValidationState.MIN_PURCHASE_FINDER;
      return;
    } else if (
      product.quantity < updatedCartItem.quantityInformation?.minPurchase
    ) {
      quantityChangeHints[updatedCartItem.itemId] =
        BasketQuantityValidationState.MIN_PURCHASE_CART;
      return;
    } else if (
      product.quantity > updatedCartItem.quantityInformation?.maxPurchase &&
      source === BasketActionSource.CART
    ) {
      quantityChangeHints[updatedCartItem.itemId] =
        BasketQuantityValidationState.MAX_PURCHASE_CART;
      return;
    } else if (
      product.quantity > updatedCartItem.quantityInformation?.maxPurchase &&
      source === BasketActionSource.FINDER
    ) {
      quantityChangeHints[updatedCartItem.itemId] =
        BasketQuantityValidationState.MAX_PURCHASE_FINDER;
      return;
    } else if (
      updatedCartItem.quantityInformation?.minPurchase <= product.quantity &&
      updatedCartItem.quantityInformation?.maxPurchase >= product.quantity &&
      updatedCartItem.quantityInformation?.purchaseSteps > 1 &&
      source === BasketActionSource.FINDER
    ) {
      quantityChangeHints[updatedCartItem.itemId] =
        BasketQuantityValidationState.STEP_SIZE_FINDER;
      return;
    }
  });

  useCartStore().hints.quantityChange = quantityChangeHints;
}

function compareCartsForDrugRemoval(
  cart_Old: Pick<ShopCart, 'items'>,
  cart_New: Pick<ShopCart, 'items'>,
) {
  const drugItemCount = cart_Old.items.filter(
    (x) => x.product?.flags?.isDrug,
  ).length;

  if (drugItemCount === 0) {
    return false;
  } else {
    const drugItemCountNew = cart_New.items.filter(
      (x) => x.product?.flags?.isDrug,
    ).length;
    if (drugItemCountNew < drugItemCount) {
      return true;
    }
  }
  return false;
}

/**
 * Returns the existing cartItem if it exists AND isn't a freegift
 * This is important because freegifts are not considered when updating the cart
 * e.g. Product A is in the Cart as freegift, but we can still just normally add Product A to the cart (not as freegift, but as normal item to buy)
 */
function findExistingItemWithExceptions(
  cart: Pick<ShopCart, 'items'>,
  productId: string,
) {
  const existingCartItem = cart.items.find(
    (item) =>
      item.type === LineItemType.product &&
      item.productId === productId &&
      !item.flags?.isFreeGift,
  );
  return existingCartItem;
}

function findExistingSubscription(items: LineItem[], productId: string) {
  const subscriptions = items.filter(
    (cur) => cur.type === LineItemType.subscription,
  );
  const existingCartItem = subscriptions.find(
    (subscription) => subscription.productId === productId,
  );
  return existingCartItem;
}

function findExistingCampaignItem(
  items: LineItem[],
  campaignId: string,
  campaignItems: AddCampaignBody['items'],
) {
  const campaigns = items.filter(
    (cur) =>
      cur.type === LineItemType.campaign && cur.campaign?.id === campaignId,
  );
  return campaigns.find(
    (cur) =>
      cur.children.length === campaignItems.length &&
      cur.children.every((child) => {
        return campaignItems.find(
          (item) => item.referencedId === child.productId,
        );
      }),
  );
}

function setMailchimpCampaignId(
  cart_New: Pick<ShopCart, 'token'>,
  cart_Old?: Pick<ShopCart, 'token'>,
) {
  const mailchimpIdInUrl = useRoute().query.mc_cid as string;

  if (mailchimpIdInUrl) {
    useMailchimpMarketing().value.set(cart_New.token, {
      mailchimp_campaign_id: mailchimpIdInUrl,
      creationDate: new Date(),
    });
  } else if (cart_Old?.token !== cart_New.token) {
    //** Transfer mailchimp-id to new cart. Usually happens after a guest logs in */
    const mailchimpMarketing = useMailchimpMarketing();
    const oldEntry = mailchimpMarketing.value.get(cart_Old?.token);
    if (oldEntry) {
      mailchimpMarketing.value.set(
        cart_New.token,
        mailchimpMarketing.value.get(cart_Old.token),
      );
      mailchimpMarketing.value.delete(cart_Old.token);
    }
  }
  // @TODO We do not clean this up otherwise for now. No harm in that for now.
}

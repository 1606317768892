import { shopPagesPatterns } from '~/plugins/changeRoutes';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { SiteIdent } from '~/utils/types';

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path === '/employee/recover/password') {
    const hash = to.query.hash as string;
    return navigateTo({ path: '/account/resetPassword', query: { hash } });
  }

  if (
    !useShopEnabled() &&
    shopPagesPatterns.some((regex) => regex.test(to.path))
  ) {
    return navigateTo({ path: '/404' });
  }

  if (to.path.startsWith('/shop/search') && to.params.slug?.length) {
    const site = useSiteIdent();
    if (site) {
      const search = to.params.slug[0];
      const redirect = await loadRedirect(search, site);
      if (redirect?.redirect) {
        return navigateTo(redirect.redirect, {
          redirectCode: 302,
          external: true,
        });
      }
    }
  }
});

async function loadRedirect(search: string, site: SiteIdent) {
  const result = await useSecureSessionPost(`/api/${site}/search/redirect`, {
    search,
  });
  if (!result?.redirect) {
    return null;
  }
  return result;
}

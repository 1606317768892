export function handleLoadingError(error: any) {
  // eslint-disable-next-line no-console
  console.error(error);

  // This should never happen, but we have some errors i can't explain otherwise
  if (!error) {
    useElasticApm()?.captureError({
      ...error,
      message: 'Error with missing Error-Data',
    });
    return;
  }

  if (['400', '401', '403', '404'].some((c) => error.message?.includes(c))) {
    // dont pollute sentry with user errors
    return;
  } else if (error.message?.includes('502')) {
    // error for when a backend service is down
    useElasticApm()?.captureError({
      ...error,
      message: '502 Error',
    });
  } else {
    //catch all for unknown errors
    useElasticApm()?.captureError({
      ...error,
      message: 'Unknown Error',
    });
  }
}

import StoreIds from './storeIds';
import { v4 as uuidv4 } from 'uuid';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { Result as LogoutResponse } from '~/server/api/[site]/user/logout.post';
import { handleLoadingError } from '~/utils/handleLoadingError';
import { useDialogStore, DialogIdent } from '~/stores/useDialogStore';

export enum StaticUserIdentifiers {
  ANONYMOUS = 'anonymous',
  CUSTOMER = 'customer',
  PASSWORDLESS = 'passwordless',
}

interface State {
  isLoggedIn: boolean;
  logoutInProgress: boolean;
  sessionId: string;
  userIdentifier: string;
  onLoginCbs: (() => Promise<any>)[];
  onLogoutCbs: (() => Promise<any>)[];
  browserSessionId: string;
}

export const useSessionStore = defineStore(StoreIds.SESSION, {
  state: () =>
    ({
      isLoggedIn: false,
      logoutInProgress: false,
      sessionId: '',
      userIdentifier: null,
      onLoginCbs: [],
      onLogoutCbs: [],
      browserSessionId: '',
    }) as State,
  actions: {
    init() {
      this.initSessionId();
      this.initUserIdentifier();
      this.browserSessionId = uuidv4();
      this.isLoggedIn = this.userIdentifier !== StaticUserIdentifiers.ANONYMOUS;
    },
    initSessionId() {
      const cookie = useSessionCookie();
      if (!cookie.value) {
        if (!this.sessionId && import.meta.client) this.sessionId = uuidv4();
        cookie.value = this.sessionId;
      } else {
        this.sessionId = cookie.value;
      }
    },
    initUserIdentifier() {
      const userIdentifierCookie = useUserIdentifierCookie();
      if (!userIdentifierCookie.value) {
        if (!this.userIdentifier && import.meta.client)
          this.userIdentifier = StaticUserIdentifiers.ANONYMOUS;
        userIdentifierCookie.value = this.userIdentifier;
      } else {
        this.userIdentifier = userIdentifierCookie.value;
      }
    },
    setSessionId(sessionId: string) {
      this.sessionId = sessionId;
      const cookie = useSessionCookie();
      cookie.value = sessionId;
    },
    setUserIdentifier(userIdentifier: string) {
      this.userIdentifier = userIdentifier;
      const userIdentifierCookie = useUserIdentifierCookie();
      userIdentifierCookie.value = userIdentifier;
    },
    async login(
      sessionId: string,
      userIdentifier: string = StaticUserIdentifiers.CUSTOMER,
      noCallbacks = false,
    ) {
      this.setSessionId(sessionId);
      this.setUserIdentifier(userIdentifier);
      this.isLoggedIn = true;
      if (noCallbacks) return;
      await nextTick();
      await this.execLoginCbs();
    },
    async logout(options?: { wasAutoLogout?: boolean }) {
      this.logoutInProgress = true;
      /**
       * Important to go to the homepage here
       * it avoids errors on-page for free due to missing data/broken stores on logged-in pages
       * do not await this because we want to logout asap and navigation should not block it (but already be done once the reload happens)
       */
      await navigateTo('/');
      this.killSession();

      const doLogout = async () => {
        try {
          const site = useSiteIdent();
          await useSecureSessionPost<LogoutResponse>(
            `/api/${site}/user/logout`,
          );

          this.killSession();
        } catch (e) {
          this.killSession();
          handleLoadingError(e);
        } finally {
          await this.execLogoutCbs(); //Don't care if anything fails here because of the refresh right after it
        }
      };
      doLogout();

      // Redirect/Reload to the homepage or defined url after logout
      if (options?.wasAutoLogout) {
        const data: { url: string } = await useDialogStore().openDialog(
          DialogIdent.AUTO_LOGOUT,
        );
        if (data?.url) {
          await navigateTo(data.url);
        } else {
          await navigateTo('/');
        }
      } else {
        await navigateTo('/');
      }
    },
    execLogoutCbs() {
      return Promise.all(this.onLogoutCbs.map((cb) => cb()));
    },
    execLoginCbs() {
      return Promise.all(this.onLoginCbs.map((cb) => cb()));
    },
    killSession(sessionId = uuidv4()) {
      this.setSessionId(sessionId);
      this.setUserIdentifier(StaticUserIdentifiers.ANONYMOUS);
      this.isLoggedIn = false;
    },
    registerOnLogin(cb: () => Promise<any>) {
      this.onLoginCbs.push(cb);
    },
    registerOnLogout(cb: () => Promise<any>) {
      this.onLogoutCbs.push(cb);
    },
  },
  getters: {
    epoqSessionId(state) {
      if (import.meta.client)
        return (
          localStorage.getItem('7cb54b_session_id') ||
          '_' + state.browserSessionId
        );
    },
  },
});

function useSessionCookie() {
  return useCookie('session', {
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 1),
    maxAge: 1000 * 60 * 60 * 24 * 365 * 1,
    sameSite: 'lax',
    secure: true,
  });
}

function useUserIdentifierCookie() {
  return useCookie('userIdentifier', {
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 1),
    maxAge: 1000 * 60 * 60 * 24 * 365 * 1,
    sameSite: 'lax',
    secure: true,
  });
}
